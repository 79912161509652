import { getDevice, getProcess } from 'app/utils/utilities';
import axios from 'axios';
import context from 'react-bootstrap/esm/AccordionContext';
import apiConfig from './apiConfig';
import exceptionService from './exceptionService';
import localStorageService from './localStorageService';

class httpService {
  timeoutEnv = process.env.REACT_APP_TIMEOUT_REQUEST
    ? process.env.REACT_APP_TIMEOUT_REQUEST
    : 10000;

  process = getProcess();

  device = getDevice();

  limit = process.env.REACT_APP_MAX_ITEM_PER_PAGE || 10;

  requiredDataObj = {
    process: this.process,
    device: this.device,
  };

  requiredDataString = `process=${this.process}&device=${this.device}`;

  accessToken = localStorageService.getItem('accessToken') || '';

  useQueryString = ['get'];

  axiosInstance = axios.create({
    baseURL: `${apiConfig.MIDDLEWARE.endPoint}/api/`,
    timeout: parseInt(this.timeoutEnv),
  });

  callEndpoint = async (
    process = '',
    method = 'get',
    url = '',
    allowedSucessStatus = [],
    allowedErrorStatus = [],
    queryStrings = '',
    body = {},
    options = {},
    handleAllowedError = false,
    fullResponse = false
  ) => {
    let response;
    try {
      const bodyQuery = this.addRequiredDataToQueryAndBody(
        this.useQueryString,
        method,
        queryStrings,
        body,
        this.requiredDataString,
        this.requiredDataObj
      );

      queryStrings = bodyQuery.query;
      body = bodyQuery.body;

      const requestConfig = {
        headers: {
          Authorization: !this.accessToken ? '' : `Bearer ${this.accessToken}`,
        },
        url: `${url}${queryStrings}`,
        method,
        data: body,
        validateStatus: (status) => {
          return [...allowedSucessStatus, ...allowedErrorStatus].includes(
            status
          );
        },
        ...options,
      };

      response = await this.axiosInstance.request(requestConfig);
    } catch (error) {
      //Validate response is not undefined
      response = exceptionService.handleHttpCatch(
        error,
        method,
        url,
        queryStrings,
        body,
        process,
        context,
        '',
        ''
      );
    }

    return await this.validateResponse(
      process,
      allowedSucessStatus,
      allowedErrorStatus,
      method,
      url,
      queryStrings,
      body,
      response,
      handleAllowedError,
      fullResponse
    );
  };

  validateResponse = async (
    process,
    allowedSuccessStatus,
    allowedErrorStatus,
    method,
    url,
    queryStrings,
    body,
    response,
    handleAllowedError,
    fullResponse
  ) => {
    const status = response?.status || 503;

    const successStatus = allowedSuccessStatus.includes(status);
    const errorStatus = allowedErrorStatus.includes(status);

    if (!successStatus && !errorStatus) {
      exceptionService.throwErrorHandled(
        process,
        `validateResponse`,
        { method, url, queryStrings, body },
        response,
        true,
        '',
        ''
      );
    } else if (errorStatus && !handleAllowedError) {
      exceptionService.throwErrorHandled(
        process,
        `validateResponse`,
        { method, url, queryStrings, body },
        response,
        true,
        '',
        ''
      );
    }

    const data = response?.data?.data || response?.data;

    return fullResponse ? response : data;
  };

  addRequiredDataToQueryAndBody = (
    useQueryString,
    method,
    query,
    body,
    requiredDataString,
    requiredDataObj
  ) => {
    if (useQueryString.includes(method)) {
      query =
        !query || !query.includes('process=')
          ? `?${requiredDataString}&${query}`
          : `?${query}`;
    } else {
      body = !body?.process ? { requiredDataObj, ...body,  } : body;
    }

    return { query, body };
  };
}

export default new httpService();
