const common = {
  path: {
    home: 'Home',
    settings: 'Settings',
    ige: 'IGE',
    sessions: 'Sessions',
    vasafe: 'Vasafe',
    customerSatisfaction: 'Customer satisfcation',
  },
  navigations: {
    ige: {
      name: 'IGE',
      description: 'IGE module',
      menu: {
        winners: 'Winners',
        awards: 'Awards',
        manageAwards: 'ManageAwards',
      },
    },
    vasafe: {
      name: 'Vasafe',
      description: 'Vasafe module',
      menu: {
        integrators: 'Integrator',
        products: 'Product',
        dashboard: 'Dashboard',
      },
    },
    settings: {
      name: 'Settings',
      description: 'Settings module',
      menu: {
        users: 'Users',
        roles: 'Roles',
        permissions: 'Permissions',
        menu: 'Menus',
      },
    },
    customerSatisfaction: {
      name: 'Customer satisfaction',
      description: 'Module of customer satisfaction',
      menu: {
        dashboard: 'Dashboard',
        poll: 'Polls',
      },
    },
  },
  header: {
    signout: 'Sign out',
  },
  swal: {
    delete: {
      title: 'Are you sure you want to delete the record?',
      text: 'The record cannot be retrieved',
      yes: 'Delete',
      no: 'Cancel',
      loading: {
        title: 'We are processing the request',
        text: 'Deleting record ...',
      },
      success: {
        title: 'Success',
        text: 'The record was successfully deleted',
      },
    },
  },
  smartTable: {
    searchButton: 'Search:',
    searchPlaceholder: 'Search',
    zeroRecords: 'No matches found',
    detail: 'Detail',
  },
  table: {
    first: 'First',
    last: 'Last',
    next: 'Next',
    previous: 'Previous',
    sSearch: 'Search:',
    zeroRecords: 'No matches found',
    detail: 'Detail',
  },
  bool: {
    yes: 'Yes',
    no: 'No',
  },
  error: {
    data: 'An error occurred while fetching the information',
    dateBefore: 'The date cannot be later than the current one',
    startDateAfterEndDate: 'The start date cannot be greater than the end date',
    endDateBeforeStartDate: 'The end date cannot be less than the start date',
  },
  form: {
    name: 'Name',
    required: 'Required',
    maxChar: 'Exceeds the maximum characters',
    match: 'Alphanumeric values ​​only',
  },
  messages: {
    services: {
      error: {
        404: 'No records found.',
        500: 'An unexpected error has occurred. 500',
        default: 'An unexpected error has occurred.',
      },
      messages: {
        'Company already exists': 'The company already exists',
      },
    },
    tables: {
      empty: 'No records found.',
      fatal:
        'An error occurred while bringing the information, contact the administrator',
    },
  },
  risk: {
    low: 'Low',
    medium: 'Medium',
    high: 'High',
  },
};

export default common;
