import history from '@history.js';
import localStorageService from 'app/services/localStorageService';

export const SET_USER_DATA = 'USER_SET_DATA';
export const REMOVE_USER_DATA = 'USER_REMOVE_DATA';
export const REMOVE_USER_DATA_EXPIRED = 'REMOVE_USER_DATA_EXPIRED';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export const setUserData = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    });
  };
};

export const logoutUserExpired = () => {
  return (dispatch) => {
    removeUserLocalStorage();

    history.push({
      pathname: '/session/signin',
      state: { expired: true },
    });

    dispatch({
      type: USER_LOGGED_OUT,
    });

    dispatch({
      type: REMOVE_USER_DATA_EXPIRED,
    });
  };
};

export const logoutUser = () => {
  return (dispatch) => {
    removeUserLocalStorage();

    history.push({
      pathname: '/session/signin',
    });

    dispatch({
      type: USER_LOGGED_OUT,
    });

    dispatch({
      type: REMOVE_USER_DATA,
    });
  };
};

export const removeUserLocalStorage = () => {
  localStorageService.removeItem('refreshToken');
  localStorageService.removeItem('accessToken');
};
