const ige = {
  winners: {
    title: 'IGE - Ganadores',
    name: 'Ganadores',
    headers: {
      name: 'Nombre',
      option: 'opción',
      fullName: 'Nombre Completo',
      msisdn: 'Msisdn',
      district: 'Distrito',
      province: 'Provincia',
      clientSinceDate: 'Fecha de ingreso',
      clientSinceYears: 'Antiguedad',
      maxPlaysPerDay: 'Cantidad de jugadas',
    },
  },
  awards: {
    title: 'IGE - Premios',
    name: 'Premios',
    headers: {
      name: 'Nombre',
      option: 'Orden',
      type: 'Tipo',
      backgroundColor: 'Color de fondo',
      fontColor: 'Color de letra',
      startRange: 'Rango de inicio',
      endRange: 'Rango de termino',
      zones: 'Zonas',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de termino',
      action: 'Acción',
    }
  }
};

export default ige;