import { t } from '../../lang/i18nSettings';

const langModule = 'common.messages';

const messages = {
  services: {
    error: {
      404: t(`${langModule}.services.error.404`),
      500: t(`${langModule}.services.error.500`),
      default: t(`${langModule}.services.error.default`),
    },
    messages: {
      'Company already exists': t(
        `${langModule}.services.message.Company already exists`
      ),
    },
  },
  tables: {
    empty: t(`${langModule}.tables.empty`),
    fatal: t(`${langModule}.tables.fatal`),
  },
};

export default messages;
