import sessions from './sessions';
import common from './common';
import vasafe from './vasafe';
import ige from './ige';
import settings from './settings';
import customerSatisfaction from './customerSatisfaction';

const en = {
  translation: {
    settings,
    ige,
    sessions,
    vasafe,
    common,
    customerSatisfaction,
  },
};

export default en;
