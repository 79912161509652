import React from 'react';
import { Redirect } from 'react-router-dom';
import sessionsRoutes from './views/sessions/sessionsRoutes';
import AuthGuard from './auth/AuthGuard';
import igeRoutes from './views/ige/igeRoutes';
import vasafeRoutes from './views/vasafe/vasafeRoutes';
import settingsRoutes from './views/settings/settingsRoutes';
import customerSatisfactionRoutes from './views/customerSatisfaction/customerSatisfactionRoutes';

const redirectRoute = [
  {
    path: '/',
    exact: false,
    component: () => <Redirect to='/session/signin' />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to='/session/404' />,
  },
];

const routes = [
  ...sessionsRoutes,
  {
    path: '/',
    component: AuthGuard,
    routes: [
      ...igeRoutes,
      ...vasafeRoutes,
      ...settingsRoutes,
      ...customerSatisfactionRoutes,
      ...redirectRoute,
      ...errorRoute,
    ],
  },
];

export default routes;
