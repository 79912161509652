const settings = {
  menus: {
    title: 'Settings - Menus',
    name: 'Menus',
  },
  permissions: {
    title: 'Settings - Permissions',
    name: 'Permissions',
  },
  roles: {
    title: 'Settings - Roles',
    name: 'Roles',
  },
  users: {
    title: 'Settings - Users',
    name: 'Users'
  },
};

export default settings;
