import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import RootReducer from './reducers/RootReducer';

const middlewares = [thunk];

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = persistReducer(persistConfig, RootReducer);
const store = createStore(reducers, applyMiddleware(...middlewares));
const persistor = persistStore(store);

export { store, persistor };
