import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppContext from 'app/appContext';
import GullLayout from 'app/GullLayout/GullLayout';
import { checkToken, refreshToken } from 'app/redux/actions/LoginActions';
import { flatMap } from 'lodash';

class AuthGuard extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    let authenticated = this.props?.user?.auth?.accessToken ? true : false;

    if (authenticated) {
      const { pathname } = this.props.location;
      let authPath = false;
      for (let menu of this.props?.user?.navigations) {
        if (!authPath) {
          for (let submenu of menu.sub) {
            if (pathname.includes(submenu.path)) {
              authPath = true;
              break;
            }
          }
        }
      }
      authenticated = authenticated && authPath;
    }

    this.state = {
      authenticated,
      routes,
    };
  }

  componentDidMount() {
    this.setState({
      routes: flatMap(this.state.routes, (item) => {
        if (item.routes) {
          return [...item.routes];
        }
        return [item];
      }),
    });

    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
  }

  componentDidUpdate() {
    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
  }

  async shouldComponentUpdate(nextProps, nextState) {
    try {
      await this.props.checkToken(this.props?.user);
    } catch {
      await this.props.refreshToken(this.props?.user);
    }
  }

  redirectRoute(props) {
    const { location, history } = props;
    const { pathname } = location;

    history.push({
      pathname: '/session/signin',
      state: { redirectUrl: pathname },
    });
  }

  render() {
    return this.state.authenticated ? (
      <Fragment>
        <GullLayout route={this.props.route}></GullLayout>
      </Fragment>
    ) : null;
  }
}

AuthGuard.contextType = AppContext;

const mapStateToProps = (reducerData) => ({
  user: reducerData.user,
});

export default withRouter(
  connect(mapStateToProps, {
    checkToken,
    refreshToken,
  })(AuthGuard)
);
