import history from '@history.js';
import {
  setUserData,
  logoutUserExpired,
  REMOVE_USER_DATA,
} from './UserActions';
import { navigations } from './../../navigations';
import iamAuthService from '../../services/iam/AuthService';

export const LOGOUT = 'LOGOUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const RESET_PASSWORD = 'RESET_PASSWORD';

const profileNavigations = (menus) => {
  const nav = [];
  const permissions = {};
  for (let menu of menus) {
    for (let m of navigations) {
      if (menu.tag === m.tag) {
        let menuAux = Object.assign({}, m);
        menuAux.sub = [];
        for (let submenu of menu.submenus) {
          //verify submenu
          for (let sm of m.sub) {
            if (sm.tag === submenu.tag) {
              let submenuAux = Object.assign({}, sm);
              menuAux.sub.push(submenuAux);
              permissions[submenuAux.path] = submenu.actions;
              break;
            }
          }
        }

        if (menuAux.sub.length > 0) {
          nav.push(menuAux);
        }
        break;
      }
    }
  }

  if (nav.length > 0) {
    return { navProfile: nav, permissions };
  }
  return false;
};

export const loginWithEmailAndPassword = ({ email, password }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOGIN_LOADING,
      });

      const data = await iamAuthService.loginWithEmailAndPassword(
        email,
        password
      );

      if (data?.errors) {
        return dispatch({
          type: LOGIN_ERROR,
          payload: 'badCredentials',
        });
      }

      let { user, menus, defaultMenu } = data;

      iamAuthService.setSession(user?.auth);

      if (!menus) {
        return dispatch({
          type: LOGIN_ERROR,
          payload: 'profileError',
        });
      }

      let { navProfile, permissions } = profileNavigations(menus);
      console.log('navProfile', navProfile);
      console.log('permissions', permissions);
      if (navProfile) {
        dispatch(
          setUserData({
            ...user,
            menus,
            navigations: navProfile,
            permissions,
          })
        );

        if (!defaultMenu) {
          defaultMenu = navProfile[0].sub[0].path;
        }

        history.push({
          pathname: defaultMenu,
        });

        return dispatch({
          type: LOGIN_SUCCESS,
        });
      }
    } catch (err) {
      return dispatch({
        type: LOGIN_ERROR,
        payload: 'catchError',
      });
    }
  };
};

export const checkToken = (user) => {
  return async (dispatch) => {
    return await iamAuthService.checkToken(user?.auth?.accessToken);
  };
};

export const refreshToken = (user) => {
  return async (dispatch) => {
    try {
      if (!user?.auth?.refreshToken) {
        dispatch(logoutUserExpired());
      }
      const response = await iamAuthService.refreshToken(
        user?.auth?.refreshToken
      );
      dispatch(
        setUserData({
          ...user,
          auth: response,
        })
      );
    } catch (err) {
      dispatch(logoutUserExpired());
    }
  };
};

export const resetPassword = ({ email }) => {
  return (dispatch) => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD,
    });
  };
};

export const disabledExpiredSession = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_USER_DATA,
    });
  };
};
