import $ from 'jquery';
import { isBrowser, isMobile } from 'react-device-detect';
import 'datatables.net';
import { nanoid } from 'nanoid';
import { v4 as uuid } from 'uuid';
import localStorageService from '../services/localStorageService';
import messages from './messages';

export const formatModalValues = (values) => {
  let data = { ...values };
  for (let key in data) {
    if (!data[key] && data[key] !== 0) {
      data[key] = '';
    }
    if (typeof data[key] === 'boolean') {
      data[key] = data[key] ? '1' : '0';
    }
  }
  return data;
};

export const validateResponseTablePaginated = (
  response,
  allPages,
  currentPage,
  nextPage
) => {
  let rows = [];
  let pages = allPages;
  let activePage = currentPage;

  if (response.status === 200 && response.data && response.data.data) {
    const values = response.data.data;
    rows = values.items;
    pages = values.meta.totalPages;
    activePage = nextPage;
  }

  return { rows, pages, activePage, loading: false };
};

export const validateErrorTablePaginated = (err) => {
  const message =
    typeof err.response !== 'undefined' && err.response.status === 404
      ? messages.tables.empty
      : messages.tables.fatal;
  return {
    error: true,
    tableErrorMessage: message,
    loading: false,
    rows: [],
  };
};

export const newObjectCreate = (keys, defaultValues) => {
  const data = {};
  for (let key of keys) {
    if (defaultValues[key]) {
      data[key] = defaultValues[key];
    } else {
      data[key] = '';
    }
  }
  return data;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const calculePercentage = (arrayData, arrayTotal) => {
  const divisor = arrayData.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
  const dividend = arrayTotal.reduce(
    (a, b) => parseFloat(a) + parseFloat(b),
    0
  );
  return parseInt((divisor / dividend) * 100);
};

export const renderDataTable = (
  translation = {
    sSearch: 'Search:',
    first: 'First',
    last: 'Last',
    next: 'Next',
    previous: 'Prev',
    zeroRecords: 'No records to display',
  },
  idTable = 'table',
  customOptions = {}
) => {
  const dataTable = $(`#${idTable}`);
  const isDataTable = $.fn.dataTable.isDataTable(dataTable);
  const { sSearch, first, last, next, previous, zeroRecords } = translation;

  if (!isDataTable) {
    const options = {
      pageLength: 10,
      lengthChange: false,
      paging: true,
      bInfo: false,
      order: [],
      columnDefs: [{ className: 'text-left', targets: '_all' }],
      oLanguage: {
        sSearch,
        zeroRecords,
      },
      dom: 'fltpi',
      language: {
        paginate: {
          first,
          last,
          next,
          previous,
        },
      },
      ...customOptions,
    };
    $(document).ready(function () {
      dataTable.DataTable(options);
      $('#table_filter').addClass('form-group');
      $('#table_filter label').addClass('text-primary');
      $('#table_filter input').addClass('form-control');
    });
  } else {
    dataTable.DataTable().destroy();
    renderDataTable(translation, idTable);
  }
};

export function setPageTitle(pageTitle = '', metaDescription = '') {
  let title = pageTitle ? `ItmoLabs Admin | ${pageTitle}` : 'ItmoLabs Admin';
  document.title = title;
  document
    .querySelector('meta[name="description"]')
    .setAttribute('content', metaDescription);
}

export const filterToBody = (filter) => {
  const {
    range,
    productsCampaigns,
    integrators,
    startDate,
    endDate,
    operatorId,
  } = filter;

  const body = {
    range: range.value,
  };

  if (range.value === '-1') {
    body.startDate = startDate;
    body.endDate = endDate;
  }

  if (productsCampaigns.length > 0 && productsCampaigns[0].value !== '-1') {
    body.campaigns = productsCampaigns.map((item) => item.value).join();
  }

  if (integrators.length > 0 && integrators[0].value !== '-1') {
    body.integrators = integrators.map((item) => item.value).join();
  }

  if (operatorId) {
    body.operatorId = operatorId;
  }

  return body;
};

export const validateStatusAndData = (
  response,
  status,
  key = 'data',
  subkey = 'data'
) => {
  return response.status === status && response[key] && response[key][subkey];
};

export const getDeviceType = () => {
  if (isBrowser) {
    return 'browser';
  } else if (isMobile) {
    return 'mobile';
  }
  return 'not-detected';
};

export const isItmolabs = (str, search = '@itmolabs.com') => {
  if (!str) return false;
  return str.search(search) >= 0 ? true : false;
};

export const getDevice = () => {
  let device = localStorageService.getItem('device');

  if (!device) {
    device = nanoid();
    localStorageService.setItem('device', device);
  }

  return device;
};

export const getProcess = () => {
  let process = localStorageService.getItem('process');

  if (!process) {
    process = uuid();
    localStorageService.setItem('process', process);
  }

  return process;
};
