import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Loading } from '@gull';
import history from '@history';
import AppContext from './appContext';
import routes from './RootRoutes';
import { store, persistor } from './redux/Store';
import { renderRoutes } from 'react-router-config';
import RootRoutes from './RootRoutes';


function App() {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<Loading></Loading>}>
            <Router history={history}>{renderRoutes(RootRoutes)}</Router>
          </Suspense>
        </PersistGate>
      </Provider>
    </AppContext.Provider>
  );
}

export default App;
