const common = {
  path: {
    home: 'Inicio',
    settings: 'Configuración',
    ige: 'IGE',
    sessions: 'Sessions',
    vasafe: 'Vasafe',
    customerSatisfaction: 'Satisfacción del cliente',
    demo: 'Demo'
  },
  navigations: {
    ige: {
      name: 'IGE',
      description: 'Modulo IGE',
      menu: {
        winners: 'Ganadores',
        awards: 'Premios',
      },
    },
    vasafe: {
      name: 'Vasafe',
      description: 'Modulo de Vasafe',
      menu: {
        integrators: 'Integrador',
        products: 'Producto',
        dashboard: 'Dashboard',
      },
    },
    settings: {
      name: 'Configuración',
      description: 'Modulo de configuración',
      menu: {
        users: 'Usuarios',
        roles: 'Roles',
        permissions: 'Permisos',
        menu: 'Menús',
      },
    },
    customerSatisfaction: {
      name: 'Satisfacción del cliente',
      description: 'Modulo de satisfacción del cliente',
      menu: {
        dashboard: 'Dashboard',
        poll: 'Encuestas',
      },
    },
    demo: {
      name: 'Demo',
      description: 'Modulo de prueba',
      menu: {
        dashboard: 'Dashboard',
        poll: 'Encuestas',
      },
    },
  },
  header: {
    signout: 'Cerrar sesión',
  },
  swal: {
    delete: {
      title: '¿Estás seguro que desea eliminar el registro?',
      text: 'El registro no podrá ser recuperado',
      yes: 'Eliminar',
      no: 'Cancelar',
      loading: {
        title: 'Estamos procesando la solicitud',
        text: 'Eliminando registro...',
      },
      success: {
        title: 'Éxito',
        text: 'El registro fue eliminado correctamente',
      },
    },
  },
  smartTable: {
    searchButton: 'Buscar:',
    searchPlaceholder: 'Buscar',
    zeroRecords: 'No hay información',
    detail: 'Detalle',
  },
  table: {
    first: 'Primera',
    last: 'Última',
    next: 'Siguiente',
    previous: 'Anterior',
    sSearch: 'Buscar:',
    zeroRecords: 'No se encontraron coincidencias',
    detail: 'Detalle',
  },
  bool: {
    yes: 'Si',
    no: 'No',
  },
  error: {
    data: 'Ha ocurrido un error al traer la información',
    dateBefore: 'La fecha no puede ser posterior a la actual',
    startDateAfterEndDate:
      'La fecha de inicio no puede ser mayor a la de término',
    endDateBeforeStartDate:
      'La fecha de término no puede ser menor a la de inicio',
  },
  messages: {
    services: {
      error: {
        404: 'No se han encontrado registros',
        500: 'Ha ocurrido un error inesperado. 500',
        default: 'Ha ocurrido un error inesperado.',
      },
      messages: {
        'Company already exists': 'La compañía ya existe',
      },
    },
    tables: {
      empty: 'No se han encontrado registros',
      fatal:
        'Ha ocurrido un error al traer la información, contacte al administrador',
    },
  },
  enums: {
    paymentType: {
      1: 'Carrier Billing',
    },
    deviceType: {
      1: 'Móvil',
      2: 'Computadora',
    },
    connection: {
      1: '3G',
      2: '4G',
      3: '5G',
      4: 'Wifi',
      5: 'Router móvil',
      6: 'Movil',
    },
    originType: {
      1: 'App',
      2: 'Web',
    },
  },
  risk: {
    low: 'Bajo',
    medium: 'Medio',
    high: 'Alto',
  },
};

export default common;
