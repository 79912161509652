const vasafe = {
  dashboard: {
    title: 'Vasafe - Dashboard',
    name: 'Dashboard',
    loading: 'Obtaining information',
    filter: {
      all: 'All',
      dates: {
        today: 'Today',
        yesterday: 'Yesterday',
        thisWeek: 'This week',
        lastWeek: 'Last week',
        thisMonth: 'This month',
        lastMonth: 'Last month',
        custom: 'Custom',
      },
      required: 'Required',
      button: 'Filter',
      customDate: 'You are using a custom date',
      deactivate: 'Deactivate',
    },
    cards: {
      products: {
        total: 'Total products',
        traffic: 'Products with traffic',
      },
      campaigns: {
        active: 'Active campaigns',
        hits: 'Hit campaigns',
      },
      trafficGoodBad: 'Stable traffic',
      hits: 'Hits',
      pageviews: 'PageViews',
      conversions: 'Conversions',
      trafficBadPageviews: 'Risky traffic vs PageViews',
      goodTraffic: 'Good traffic',
      badTraffic: 'Risky traffic',
      notData: 'Information not found',
    },
    chartLabels: {
      low: 'Low',
      medium: 'Medium',
      high: 'High',
      conversions: 'Conversions',
    },
    charts: {
      hitsConversions: {
        title: 'Hits / Conversions',
        yText1: 'Hits',
        yText2: 'Conversions',
      },
      pageviewsConversions: {
        title: 'PageViews / Conversions',
        yText1: 'PageViews',
        yText2: 'Conversions',
      },
      conversions: {
        title: 'Conversions',
      },
      conversionsAttempts: {
        title: 'Conversions attempts',
      },
      riskTypes: {
        title: 'Risks types',
      },
      notData: 'Information not found',
    },
    alerts: {
      title: 'Alerts',
      headers: {
        company: 'Company',
        po: 'PO',
        email: 'Email',
        type: 'Type',
        action: 'Action',
      },
    },
    tabs: {
      publishers: 'Publishers',
      adNetworks: 'AdNetworks',
      products: 'Products',
      companies: 'Integrators',
      urls: 'Campaigns',
      headers: {
        name: 'Name',
        total: 'Total',
      },
    },
  },
  products: {
    name: 'Product',
    title: 'Vasafe - Product',
    headers: {
      uuid: 'uuid',
      name: 'Name',
      active: 'Active',
      score: 'Score',
      action: 'Action',
    },
    buttons: {
      create: 'Create product',
      edit: 'Edit',
      deactivate: 'Deactivate',
    },
    editProduct: {
      titleNew: 'New product',
      titleEdit: 'Edit product',
      buttonNew: 'Create product',
      buttonEdit: 'Edit product',
      loading: 'Loading...',
      form: {
        name: 'Name',
        required: 'Required',
        maxChar: 'Exceeds the maximum characters',
        match: 'Alphanumeric values ​​only',
      },
    },
  },
  companies: {
    name: 'Integrator',
    title: 'Vasafe - Integrator',
    headers: {
      name: 'Name',
      active: 'Active',
      action: 'Action',
    },
    buttons: {
      create: 'Create integrator',
      edit: 'Edit',
      deactivate: 'Deactivate',
    },
    bool: {
      yes: 'Yes',
      no: 'No',
    },
    error: {
      data: 'An error occurred while fetching the information',
    },
    table: {
      first: 'First',
      last: 'Last',
      next: 'Next',
      previous: 'Previous',
      sSearch: 'Search:',
    },
    editCompany: {
      titleNew: 'New integrator',
      titleEdit: 'Edit integrator',
      buttonNew: 'Create integrator',
      buttonEdit: 'Edit integrator',
      loading: 'Loading...',
      form: {
        name: 'Name',
        required: 'Required',
        maxChar: 'Exceeds the maximum characters',
        match: 'Alphanumeric values ​​only',
      },
    },
  },
  issue: {
    name: 'Alert',
    title: 'Vasafe - Alert',
    loading: 'Obtaining information',
    alert: 'Alert: ',
    detail: 'Detail',
    company: 'Integrator: ',
    paymentType: 'Payment type: ',
    deviceType: 'Device: ',
    connection: 'Connection: ',
    product: 'Product: ',
    publisherUrl: 'Publisher URL: ',
    landingUrl: 'Landing URL: ',
    risk: 'Risk: ',
    riskType: 'Risk type: ',
    createdAt: 'Created: ',
    states: 'States workflows',
    images: 'Images',
    origins: 'Origins',
    history: 'History',
    issueWorkflows: {
      state: 'State',
      date: 'Date',
    },
    issueOrigins: {
      type: 'Type',
      url: 'Value',
    },
    issueHistory: {
      action: 'Action:',
      responsible: 'Responsible:',
      email: 'Email:',
      description: 'Description:',
      comments: 'Comments:',
      date: 'Date:',
    },
    error: {
      httpError: 'An error has occurred',
      notId: 'Id not received',
    },
  },
};

export default vasafe;
