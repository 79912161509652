const customerSatisfaction = {
  dashboard: {
    title: 'Customer Satisfaction - Dashboard',
    name: 'Dashboard',
    loading: 'Obtaining information',
    notData: 'Information not found',
    cards: {
      answersVsSent: 'Answers vs sent',
      scoreNps: 'Score NPS',
      detractorsVsPromoters: 'Detractors vs Promoters',
      totalAnswers: 'Total answers',
    },
    charts: {
      npsBreakdown: {
        title: 'NPS Breakdown',
        labels: {
          detractors: 'Detractors',
          passives: 'Passives',
          promoters: 'Promoters',
        }
      },
      totalAnswers: {
        title: 'Answers quantity',
      },
      answersDetail: {
        title: 'Answers detail',
        legend: 'Detractors [null - 6], Passives [7 - 8], Promoters [9 - 10]'
      }
    }
  },
  poll: {
    title: 'Customer Satisfaction - Polls',
    name: 'Polls',
    loading: 'Obtaining information',
    headers: {
      name: 'Name',
      pollType: 'Type',
      channel: 'Channel',
      shortNumber: 'Short number',
      longNumber: 'Long number',
      action: 'Action',
    },
  },
};

export default customerSatisfaction;
