const sessions = {
  signin: {
    subtitle: 'Nice to see you again',
    title: 'Welcome back',
    about:
      'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. ',
    login: 'Sign In',
    email: 'Email',
    emailInvalid: 'Invalid email',
    emailError: 'Required email',
    password: 'Password',
    passwordError: 'Required password',
    button: 'Sign in',
    loading: 'Loading...',
    badCredentials: 'Email or password bad',
    profileError: 'Error profile',
    catchError: 'Unexpected error try again',
    badNetwork: 'Server conection error',
    sessionExpired: {
      title: 'Sorry',
      text: 'Session expired',
    },
  },
  error: {
    errorMessage: 'Esta página no existe!',
    button: 'Volver al inicio',
  },
};

export default sessions;
