import { t } from './../lang/i18nSettings';

const langCommon = 'common.navigations';

export const navigations = [
  {
    tag: 'ige',
    name: t(`${langCommon}.ige.name`),
    description: t(`${langCommon}.ige.description`),
    type: 'dropDown',
    icon: 'i-Start-2',
    sub: [
      {
        tag: 'ige_awards_editable',
        name: t(`${langCommon}.ige.menu.manageAwards`),
        path: '/ige/manageAwards',
        type: 'link',
      },
      {
        tag: 'ige_awards',
        name: t(`${langCommon}.ige.menu.awards`),
        path: '/ige/awards',
        type: 'link',
      },
      {
        tag: 'ige_winners',
        name: t(`${langCommon}.ige.menu.winners`),
        path: '/ige/winners',
        type: 'link',
      },
    ],
  },
  {
    tag: 'settings',
    name: t(`${langCommon}.settings.name`),
    description: t(`${langCommon}.settings.description`),
    type: 'dropDown',
    icon: 'i-Gears',
    sub: [
      {
        tag: 'settings_users',
        name: t(`${langCommon}.settings.menu.users`),
        path: '/settings/users',
        type: 'link',
      },
      {
        tag: 'settings_roles',
        name: t(`${langCommon}.settings.menu.roles`),
        path: '/settings/roles',
        type: 'link',
      },
      {
        tag: 'settings_permissions',
        name: t(`${langCommon}.settings.menu.permissions`),
        path: '/settings/permissions',
        type: 'link',
      },
      {
        tag: 'settings_menus',
        name: t(`${langCommon}.settings.menu.menu`),
        path: '/settings/menus',
        type: 'link',
      },
    ],
  },
  {
    tag: 'vasafe',
    name: t(`${langCommon}.vasafe.name`),
    description: t(`${langCommon}.vasafe.description`),
    type: 'dropDown',
    icon: 'i-Computer-Secure',
    sub: [
      {
        tag: 'vasafe_companies',
        name: t(`${langCommon}.vasafe.menu.integrators`),
        path: '/vasafe/company',
        type: 'link',
      },
      {
        tag: 'vasafe_products',
        name: t(`${langCommon}.vasafe.menu.products`),
        path: '/vasafe/product',
        type: 'link',
      },
      {
        tag: 'vasafe_dashboard',
        name: t(`${langCommon}.vasafe.menu.dashboard`),
        path: '/vasafe/dashboard',
        type: 'link',
      },
      {
        tag: 'vasafe_issue',
        name: '',
        path: '/vasafe/issue',
        type: 'hidden',
      },
    ],
  },
  {
    tag: 'customer_satisfaction',
    name: t(`${langCommon}.customerSatisfaction.name`),
    description: t(`${langCommon}.customerSatisfaction.description`),
    type: 'dropDown',
    icon: 'i-Computer-Secure',
    sub: [
      {
        tag: 'customer_satisfaction_dashboard',
        name: t(`${langCommon}.customerSatisfaction.menu.dashboard`),
        path: '/customerSatisfaction/dashboard',
        type: 'link',
      },
      {
        tag: 'customer_satisfaction_poll',
        name: t(`${langCommon}.customerSatisfaction.menu.poll`),
        path: '/customerSatisfaction/poll',
        type: 'link',
      },
    ],
  },
  {
    tag: 'demo',
    name: t(`${langCommon}.demo.name`),
    description: t(`${langCommon}.demo.description`),
    type: 'dropDown',
    icon: 'i-Computer-Secure',
    sub: [
      {
        tag: 'demo_dashboard',
        name: t(`${langCommon}.demo.menu.dashboard`),
        path: '/demo/dashboard',
        type: 'link',
      },
    ],
  },
];
