import { lazy } from 'react';

const Users = lazy(() => import('./Users'));
const Roles = lazy(() => import('./Roles'));
const Permissions = lazy(() => import('./Permissions'));
const Menus = lazy(() => import('./Menus'));

const settingsRoutes = [
  {
    path: '/settings/users',
    component: Users,
  },
  {
    path: '/settings/roles',
    component: Roles,
  },
  {
    path: '/settings/permissions',
    component: Permissions,
  },
  {
    path: '/settings/menus',
    component: Menus,
  },
];

export default settingsRoutes;
