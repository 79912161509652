import sessions from './sessions';
import common from './common';
import vasafe from './vasafe';
import ige from './ige';
import settings from './settings';
import customerSatisfaction from './customerSatisfaction';
import demo from './demo';

const es = {
  translation: {
    settings,
    ige,
    sessions,
    vasafe,
    common,
    customerSatisfaction,
    demo,
  },
};

export default es;
