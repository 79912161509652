const sessions = {
  signin: {
    subtitle: 'Encantada de verte de nuevo',
    title: 'Bienvenido de nuevo',
    about:
      'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. ',
    login: 'Inicia sesión',
    email: 'Correo',
    emailInvalid: 'Correo inválido',
    emailError: 'Correo requerido',
    password: 'Contraseña',
    passwordError: 'Contraseña requerida',
    button: 'Ingresar',
    loading: 'Cargando...',
    badCredentials: 'Correo y/o contraseña incorrectos',
    profileError: 'No tiene un perfil configurado',
    catchError: 'Ha ocurrido un error, vuelva a intentarlo',
    badNetwork: 'Error al contactar al servidor',
    sessionExpired: {
      title: 'Lo sentimos',
      text: 'Tu sessión ha expirado',
    },
  },
  error: {
    errorMessage: 'Esta página no existe!',
    button: 'Volver al inicio',
  },
};

export default sessions;
