const settings = {
  menus: {
    title: 'Configuración - Menús',
    name: 'Menús',
  },
  permissions: {
    title: 'Configuración - Permisos',
    name: 'Permisos',
  },
  roles: {
    title: 'Configuración - Roles',
    name: 'Roles',
  },
  users: {
    title: 'Configuración - Usuarios',
    name: 'Usuarios'
  },
};

export default settings;
