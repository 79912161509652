import { lazy } from "react";

const Company = lazy(() => import("./Company"));
const Product = lazy(() => import("./Product"));
const Dashboard = lazy(() => import("./Dashboard"));
const DemoDashboard = lazy(() => import("./DemoDashboard"));
const Issue = lazy(() => import("./Issue"));

const vasafeRoutes = [
  {
    path: "/vasafe/company",
    component: Company
  },
  {
    path: "/vasafe/product",
    component: Product
  },
  {
    path: "/vasafe/dashboard",
    component: Dashboard
  },
  {
    path: "/vasafe/issue/:id",
    component: Issue
  },
  {
    path: "/demo/dashboard",
    component: DemoDashboard
  },
];

export default vasafeRoutes;
