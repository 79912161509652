const demo = {
  dashboard: {
    title: 'Demo Dashboard',
    name: 'Dashboard',
    loading: 'Obteniendo información',
    filter: {
      all: 'Todos',
      dates: {
        today: 'Hoy',
        yesterday: 'Ayer',
        thisWeek: 'Semana actual',
        lastWeek: 'Semana anterior',
        thisMonth: 'Mes actual',
        lastMonth: 'Mes anterior',
        custom: 'Personalizada',
      },
      required: 'Requerido',
      button: 'Filtrar',
      customDate: 'Está usando una fecha personalizado',
      deactivate: 'Desactivar',
    },
    cards: {
      products: {
        total: 'Productos totales',
        traffic: 'Productos con tráfico',
      },
      campaigns: {
        active: 'Campañas activas',
        hits: 'Campañas con hits',
      },
      trafficGoodBad: 'Tráfico estable',
      hits: 'Hits',
      pageviews: 'PageViews',
      conversions: 'Conversiones',
      trafficBadPageviews: 'Tráfico riesgoso vs PageViews',
      goodTraffic: 'Tráfico bueno',
      badTraffic: 'Tráfico riesgoso',
      notData: 'No hay información',
    },
    chartLabels: {
      low: 'Bajo',
      medium: 'Medio',
      high: 'Alto',
      conversions: 'Conversiones',
    },
    charts: {
      hitsConversions: {
        title: 'Hits / Conversiones',
        yText1: 'Hits',
        yText2: 'Conversiones',
      },
      pageviewsConversions: {
        title: 'PageViews / Conversiones',
        yText1: 'PageViews',
        yText2: 'Conversiones',
      },
      conversions: {
        title: 'Conversiones',
      },
      conversionsAttempts: {
        title: 'Intentos de conversión',
      },
      riskTypes: {
        title: 'Tipos de riesgos',
      },
      notData: 'No hay información',
    },
    alerts: {
      title: 'Alertas',
      headers: {
        company: 'Companía',
        po: 'PO',
        email: 'Correo',
        type: 'Tipo',
        action: 'Acción',
      },
    },
    tabs: {
      publishers: 'Publishers',
      adNetworks: 'AdNetworks',
      products: 'Productos',
      companies: 'Integradores',
      urls: 'Campañas',
      headers: {
        name: 'Nombre',
        total: 'Total',
      },
    },
  },
  products: {
    name: 'Producto',
    title: 'Vasafe - Producto',
    headers: {
      uuid: 'uuid',
      name: 'Nombre',
      active: 'Activo',
      score: 'Score',
      action: 'Acción',
    },
    buttons: {
      create: 'Crear producto',
      edit: 'Editar',
      deactivate: 'Desactivar',
    },
    bool: {
      yes: 'Si',
      no: 'No',
    },
    error: {
      data: 'Ha ocurrido un error al traer la información',
    },
    table: {
      first: 'Primera',
      last: 'Última',
      next: 'Siguiente',
      previous: 'Anterior',
      sSearch: 'Buscar:',
    },
    editProduct: {
      titleNew: 'Nuevo producto',
      titleEdit: 'Editar producto',
      buttonNew: 'Crear producto',
      buttonEdit: 'Editar producto',
      loading: 'Cargando...',
      form: {
        name: 'Nombre',
        required: 'Requerido',
        maxChar: 'Excede el máximo de caracteres',
        match: 'Solo valores Alfanuméricos',
      },
    },
  },
  companies: {
    name: 'Integrador',
    title: 'Vasafe - Integrador',
    headers: {
      name: 'Nombre',
      active: 'Activo',
      action: 'Acción',
    },
    buttons: {
      create: 'Crear integrador',
      edit: 'Editar',
      deactivate: 'Desactivar',
    },
    editCompany: {
      titleNew: 'Nuevo integrador',
      titleEdit: 'Editar integrador',
      buttonNew: 'Crear integrador',
      buttonEdit: 'Editar integrador',
      loading: 'Cargando...',
      form: {
        name: 'Nombre',
        required: 'Requerido',
        maxChar: 'Excede el máximo de caracteres',
        match: 'Solo valores Alfanuméricos',
      },
    },
  },
  issue: {
    name: 'Alerta',
    title: 'Vasafe - Alerta',
    loading: 'Obteniendo información',
    alert: 'Alerta: ',
    detail: 'Detalle',
    company: 'Integrador: ',
    paymentType: 'Tipo de pago: ',
    deviceType: 'Dispositivo: ',
    connection: 'Conexión: ',
    product: 'Producto: ',
    publisherUrl: 'Publisher URL: ',
    landingUrl: 'Landing URL: ',
    risk: 'Riesgo: ',
    riskType: 'Tipo de riesgo: ',
    createdAt: 'Creado: ',
    states: 'Flujo de estados',
    images: 'Imagenes',
    origins: 'Origenes',
    history: 'Historial',
    issueWorkflows: {
      state: 'Estado',
      date: 'Fecha',
    },
    issueOrigins: {
      type: 'Tipo',
      url: 'Valor',
    },
    issueHistory: {
      action: 'Acción:',
      responsible: 'Responsable:',
      email: 'Email:',
      description: 'Descripción:',
      comments: 'Comentarios:',
      date: 'Fecha:',
    },
    error: {
      httpError: 'Ha ocurrido un error',
      notId: 'Id no recibido',
    },
  },
};

export default demo;
