import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import es from './es';
import en from './en';

const i18nSettings = {
  resources: {
    en,
    es,
  },
  lng: 'es',
  fallbackLng: 'es',
  interpolation: {
    escapeValue: true,
  },
};

i18n.use(initReactI18next).use(LanguageDetector).init(i18nSettings);

const t = (key) => {
  return i18n.t(key);
}

export { i18n, t };
