import { lazy } from 'react';

const Awards = lazy(() => import('./Awards'));

const Winners = lazy(() => import('./Winners'));

const ManageAwards = lazy(() => import('./ManageAwards'));

const igeRoutes = [
  {
    path: '/ige/manage/awards',
    component: ManageAwards,
  },
  {
    path: '/ige/awards',
    component: Awards,
  },
  {
    path: '/ige/winners',
    component: Winners,
  },
];

export default igeRoutes;
