const customerSatisfaction = {
  dashboard: {
    title: 'Satisfacción del cliente - Dashboard',
    name: 'Dashboard',
    loading: 'Obtaining information',
    cards: {
      answersVsSent: 'Respuestas vs enviadas',
      scoreNps: 'Puntaje NPS',
      detractorsVsPromoters: 'Detractores vs promotores',
      totalAnswers: 'Total de respuestas',
    },
    charts: {
      npsBreakdown: {
        title: 'NPS Breakdown',
        labels: {
          detractors: 'Detractores',
          passives: 'Pasivos',
          promoters: 'Promotores',
        }
      },
      totalAnswers: {
        title: 'Cantidad de respuestas',
      },
      answersDetail: {
        title: 'Detalle respuestas',
        legend: 'Detractores [null - 6], Pasivos [7 - 8], Promotores [9 - 10]'
      }
    }
  },
  poll: {
    title: 'Satisfacción del cliente - Encuestas',
    name: 'Encuestas',
    loading: 'Obteniendo información',
    headers: {
      name: 'Nombre',
      pollType: 'Tipo',
      channel: 'Canal',
      shortNumber: 'Número corto',
      longNumber: 'Número largo',
      action: 'Acción',
    },
  },
};

export default customerSatisfaction;
