import localStorageService from '../localStorageService';
import { getDevice, getProcess } from '../../utils/utilities';
import httpService from '../httpService';

class iamAuthService {
  iamAuth = 'v1/iam/auth';
  process = getProcess();
  device = getDevice();
  requiredDataObj = {
    process: this.process,
    device: this.device,
  };
  requiredDataString = `process=${this.process}&device=${this.device}`;

  checkToken = async (accessToken = '') => {
    return await httpService.callEndpoint(
      this.process,
      'get',
      `${this.iamAuth}/check`,
      [200],
      [],
      this.requiredDataString,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  };

  refreshToken = async (refreshToken = '') => {
    return await httpService.callEndpoint(
      this.process,
      'post',
      `${this.iamAuth}/refresh`,
      [200],
      [],
      '',
      { ...this.requiredDataObj },
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );
  };

  loginWithEmailAndPassword = async (email, password) => {
    return await httpService.callEndpoint(
      this.process,
      'post',
      `${this.iamAuth}/sign-in`,
      [200],
      [400],
      '',
      {
        email,
        password,
        ...this.requiredDataObj,
      },
      {},
      true
    );
  };

  setSession = (auth = { accessToken: null, refreshToken: null }) => {
    if (!auth || !auth?.accessToken || !auth?.refreshToken) {
      localStorageService.removeItem('accessToken', auth?.accessToken);
      localStorageService.removeItem('refreshToken', auth?.refreshToken);
      return false;
    }

    localStorageService.setItem('accessToken', auth?.accessToken);
    localStorageService.setItem('refreshToken', auth?.refreshToken);
    return true;
  };
}

export default new iamAuthService();
