import { lazy } from 'react';

const Dashboard = lazy(() => import('./Dashboard'));
const Poll = lazy(() => import('./Poll'));

const customerSatisfactionRoutes = [
  {
    path: '/customerSatisfaction/dashboard/:id?',
    component: Dashboard,
  },
  {
    path: '/customerSatisfaction/poll',
    component: Poll,
  },
];

export default customerSatisfactionRoutes;
