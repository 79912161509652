const ige = {
  winners: {
    title: 'IGE - Winners',
    name: 'Winners',
    headers: {
      name: 'Name',
      option: 'option',
      fullName: 'Full name',
      msisdn: 'Msisdn',
      district: 'District',
      province: 'Province',
      clientSinceDate: 'Since date',
      clientSinceYears: 'Since years',
      maxPlaysPerDay: 'Max play per day',
    },
  },
  awards: {
    title: 'IGE - Awards',
    name: 'Awards',
    headers: {
      name: 'Name',
      option: 'Order',
      type: 'Type',
      backgroundColor: 'Background color',
      fontColor: 'Font color',
      startRange: 'Start range',
      endRange: 'End range',
      zones: 'Zones',
      startDate: 'Start date',
      endDate: 'End Date',
      action: 'Action',
    }
  }
};

export default ige;
