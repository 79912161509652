class exceptionService {
  handleHttpCatch = async (
    error,
    method,
    url,
    queryStrings,
    body,
    requestId,
    process,
    context,
    responseCode,
    httpMessage
  ) => {
    const response =
      error?.response !== undefined ? error.response : error?.message;

    if (response?.status === undefined || response?.data === undefined) {
      this.throwInvalidHttpConnection(
        requestId,
        process,
        context,
        {
          method,
          url,
          queryStrings,
          body,
        },
        response,
        true,
        responseCode,
        httpMessage
      );
    }

    return response;
  };

  throwInvalidHttpConnection = (
    requestId,
    process,
    context,
    request,
    response,
    fireSlack,
    responseCode,
    message
  ) => {
    //alert;
  };

  throwErrorHandled = (
    requestId,
    process,
    context,
    request,
    response,
    fireSlack,
    responseCode,
    message
  ) => {
    throw new Error(response?.message || 'Ha ocurrido un error inesperado');
  };
}

export default new exceptionService();
